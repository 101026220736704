@import '../../../styles/customMediaQueries.css';

.root {
  composes: marketplaceModalFormRootStyles from global;

  justify-content: flex-start;

  @media (--viewportMedium) {
    justify-content: space-between;
  }
}
.name {
  display: flex;
  justify-content: space-between;
  margin-bottom: 24px;
}

.firstNameRoot {
  width: calc(34% - 9px);
}

.lastNameRoot {
  width: calc(66% - 9px);
}

.password {
  composes: marketplaceModalPasswordMargins from global;
}

.bottomWrapper {
  composes: marketplaceModalBottomWrapper from global;
}

.field {
  margin-bottom: 24px;
}

.selectField {
  margin-bottom: 24px;
  
  & select {
    box-shadow: none;
  }
}

.selectFieldNotSelected {
  & select {
    color: var(--colorGrey300);
  }

  & option {
    color: var(--colorGrey700);
  }
}

.locationAutocompleteInput {
  border-bottom-width: 1px;
}

.locationAutocompleteInputIcon {
  display: none;
}

.goBackBtn {
  display: flex;
  align-items: center;
  width: 100px;
  padding: 0 0 20px 0;
  color: var(--customGray);
  font-size: 12px;
  line-height: 1;
  cursor: pointer;

  & svg {
    width: 10px;
    height: 10px;
    margin-right: 10px;
  }
}

.stepTitle {
  margin: 0 0 20px;
  padding: 0;
  font-size: 30px;
  line-height: 1;
}

.stepSubTitle {
  margin: -10px 0 20px;
  padding: 0;
  color: var(--customGray);
  font-size: 14px;
  line-height: 1;
}
